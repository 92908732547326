import { axiosInstance, tokenType } from '@/config/axios'
import type { AxiosInstance, AxiosResponse } from 'axios'
import type { Login } from '@/models/auth/api/Auth'
import { useAuthStore } from '@/stores/auth'
import { checkValidToken } from '@/utils/validateJWT'
import { ContentType, HeadersType, UriEndpoint } from '@/config'

class ApiService {
  private static getAxiosInstance(tokenType: string | null): AxiosInstance {
    return axiosInstance(tokenType)
  }

  private static async handleRequest<T>(
    request: () => Promise<AxiosResponse<T>>,
    errorMessage: string,
    requiresToken: boolean = true
  ): Promise<T> {
    try {
      if (requiresToken) {
        if ((await checkValidToken()) === 0) {
          await useAuthStore().logout()
          throw new Error('Invalid token')
        }
      }
      const response = await request()
      return response.data
    } catch (error) {
      console.error(`${errorMessage}:`, error)
      throw error
    }
  }

  static async authEntity(
    nameSpace: string,
    payload: Login,
    credentials?: boolean
  ): Promise<AxiosResponse> {
    const axios = this.getAxiosInstance(tokenType.ACCESS_TOKEN)
    return axios.post(`${nameSpace}`, payload, { withCredentials: credentials })
  }

  static async refreshTokens(nameSpace: string): Promise<AxiosResponse> {
    const axios = this.getAxiosInstance(tokenType.REFRESH_TOKEN)
    return axios.post(`${nameSpace}`, {
      refreshToken: sessionStorage.getItem('refreshToken')
    })
  }

  static async sendPasswordMail(nameSpace: string): Promise<unknown> {
    return this.handleRequest(
      () =>
        this.getAxiosInstance(tokenType.ACCESS_TOKEN).post(
          `${nameSpace}/${UriEndpoint.MAIL_PASSWORD}`
        ),
      'Error sending password mail'
    )
  }

  static async createPassword(nameSpace: string, password: string): Promise<unknown> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.NO_AUTH).patch(`${nameSpace}`, { password }),
      'Error creating password',
      false
    )
  }

  static async forgotPass(nameSpace: string, payload: unknown): Promise<unknown> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.NO_AUTH).post(`${nameSpace}`, payload),
      'Error in forgot password',
      false
    )
  }

  static async downloadEntity(nameSpace: string): Promise<void> {
    return this.handleRequest(async () => {
      const response = await this.getAxiosInstance(tokenType.ACCESS_TOKEN).get(`${nameSpace}`, {
        responseType: 'blob',
        headers: { Accept: ContentType.PDF }
      })

      if (!response || !response.headers) {
        throw new Error('Response or headers are undefined')
      }

      const fileName = this.getFileName(response.headers[HeadersType.CONTENT_DISPOSITION])
      this.downloadFile(response.data, fileName)

      return response
    }, 'Error downloading file')
  }

  private static getFileName(contentDisposition: string | undefined): string {
    if (!contentDisposition) return 'download.pdf'
    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/)
    return fileNameMatch && fileNameMatch.length === 2 ? fileNameMatch[1].trim() : 'download.pdf'
  }

  private static downloadFile(data: Blob, fileName: string): void {
    const file = new Blob([data], { type: ContentType.PDF })
    const fileURL = window.URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = fileName
    link.click()
    window.URL.revokeObjectURL(fileURL)
  }

  static async createEntity<T>(nameSpace: string, payload: unknown): Promise<T> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.ACCESS_TOKEN).post(`${nameSpace}`, payload),
      'Error creating entity'
    )
  }

  static async totalActions(nameSpace: string): Promise<unknown> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.ACCESS_TOKEN).get(`${nameSpace}`),
      'Error getting total actions'
    )
  }

  static async readAllEntities<T>(nameSpace: string): Promise<T[]> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.ACCESS_TOKEN).get<T[]>(`${nameSpace}`),
      'Error reading all entities'
    )
  }

  static async readEntity<T>(nameSpace: string): Promise<T> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.ACCESS_TOKEN).get<T>(`${nameSpace}`),
      'Error reading entity'
    )
  }

  static async readResourceByEntity(nameSpace: string, id: string): Promise<unknown> {
    return this.handleRequest(
      () =>
        this.getAxiosInstance(tokenType.ACCESS_TOKEN).get(
          `${nameSpace}/${id}/${UriEndpoint.RESOURCE}`,
          { responseType: 'blob' }
        ),
      'Error reading resource by entity'
    )
  }

  static async updateEntity<T>(nameSpace: string, id: string, payload: Partial<T>): Promise<T> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.ACCESS_TOKEN).patch(`${nameSpace}/${id}`, payload),
      'Error updating entity'
    )
  }

  static async updateEntityWithResources<T>(nameSpace: string, payload: unknown): Promise<T> {
    return this.handleRequest(
      () =>
        this.getAxiosInstance(tokenType.ACCESS_TOKEN).patch(
          `${nameSpace}/${UriEndpoint.RESOURCE}`,
          payload
        ),
      'Error updating entity with resources'
    )
  }

  static async deleteEntity(nameSpace: string): Promise<void> {
    return this.handleRequest(
      () => this.getAxiosInstance(tokenType.ACCESS_TOKEN).delete(`${nameSpace}`),
      'Error deleting entity'
    )
  }
}

export { ApiService }
