<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { FilterMatchMode } from '@primevue/core/api'
import { useToast } from 'primevue/usetoast'
import type { PageState } from 'primevue/paginator'
import { t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import clock from '@/assets/lottie/clock.json'
import { getStatusEnabledHours } from '@/utils/status'
import { capitalizeString } from '@/utils/capitalize'
import { useApi } from '@/stores/api/api'
import { ApiService } from '@/services/ApiService'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseSchedule from '@/components/ui/BaseSchedule.vue'
import type { Rate, RateResponse } from '@/models/domain/rate/api/Rate'
import AppTopbar from '@/layout/AppTopbar.vue'
import { hoursRanges } from './dummy-rates'
import BaseBadge from '@/components/ui/BaseBadge.vue'

const popup = ref()
const toast = useToast()
const { loading } = storeToRefs(useApi())
const selectedRate = ref({})
const hidden = ref<boolean>(true)
const activeCreationRateDialog = ref<boolean>(false)
const activeUpdateRateDialog = ref<boolean>(false)
const activeCreateRangeHourDialog = ref<boolean>(false)
const editRangeHourDialog = ref<boolean>(false)
const rates = ref([])
const statusRangeHours = ref({
  0: t('status.enabled'),
  1: t('status.disabled')
})

const evaluateRangeHours = computed(
  () =>
    (rangeHours: number): string =>
      statusRangeHours.value[rangeHours] || ''
)

const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const alias = ref<string>('')
const costKwh = ref<number>()
const totalRecords = ref<number>()
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const getRates = async () => {
  try {
    const result = await ApiService.readAllEntities<RateResponse>(
      `${RouteNamespace.rates}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    rates.value = result['rates']
    totalRecords.value = result['totalRecords']
  } catch (error) {
    console.log(error)
  }
}
const handleAddItem = () => {
  activeCreationRateDialog.value = true
}
const handleCancel = () => {
  activeUpdateRateDialog.value = false
  activeCreationRateDialog.value = false
}
const addNewRangeHour = () => {
  activeCreateRangeHourDialog.value = true
}
const handleCancelEditRangeHours = () => {
  editRangeHourDialog.value = false
}
const handleCancelAddRangeHours = () => {
  activeCreateRangeHourDialog.value = false
}
const handleUpdate = (rowData: Rate) => {
  activeUpdateRateDialog.value = true
  selectedRate.value = rowData
}
const handleEditHoursRange = (rowData: Rate) => {
  editRangeHourDialog.value = true
  selectedRate.value = rowData
}
const handleRemove = async (event: Event, id: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      try {
        loading.value = true
        await ApiService.deleteEntity(`${RouteNamespace.rates}/${id}`)
        toast.add({
          group: 'success',
          severity: 'success',
          summary: t('detail.rate.notifications.deleteSuccess'),
          life: 3000
        })
      } catch (error) {
        console.error('Error deleting rate:', error)
      } finally {
        await getRates()
        loading.value = false
      }
    },
    undefined
  )
}

const confirmUpdateRangeHours = () => {
  console.log('confirmUpdateRangeHours')
}
const confirmInsert = async () => {
  try {
    const payload = {
      alias: alias.value,
      flatRate: costKwh.value
    }
    loading.value = true
    await ApiService.createEntity(RouteNamespace.rates, payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.rate.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error creating rate:', error)
  } finally {
    alias.value = ''
    costKwh.value = 0
    activeCreationRateDialog.value = false
    await getRates()
    loading.value = false
  }
}
const confirmUpdateRate = async () => {
  try {
    const payload = {
      alias: selectedRate.value['alias'],
      flatRate: selectedRate.value['flatRate']
    }
    loading.value = true
    await ApiService.updateEntity(RouteNamespace.rates, selectedRate.value['id'], payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.rate.notifications.updateSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error updating rate:', error)
  } finally {
    activeUpdateRateDialog.value = false
    await getRates()
    loading.value = false
  }
}

const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getRates()
}

onMounted(async () => {
  try {
    loading.value = true
    await Promise.all([getRates()])
    // items.value[1].label = alias.value
  } catch (error) {
    console.error('Error retrieving charge points:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="rate" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.rates') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.rate.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleAddItem"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="rates"
      :rows="rowsPerPage"
      :globalFilterFields="['alias', 'rateType']"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.rate.notFound')" />
      </template>
      <Column
        field="rate"
        :header="t('detail.rate.header.rate')"
        header-class="font-bold"
        class="table__rate"
      >
        <template #body="slotProps">
          {{ `${capitalizeString(slotProps['data']['alias'])}` }}
        </template>
      </Column>
      <Column
        field="rateType"
        :header="t('detail.rate.header.mode')"
        header-class="font-bold"
        class="table__type"
      >
        <template #body="slotProps">
          {{ `${capitalizeString(slotProps['data']['rateType'])}` }}
        </template>
      </Column>
      <Column
        field="mode"
        :header="t('detail.rate.header.cost')"
        header-class="font-bold"
        class="table__cost"
      >
        <template #body="slotProps">
          {{
            `${slotProps['data']['rateKWh'].toFixed(2)} ${slotProps['data']['currency']} /
              ${slotProps['data']['unit']}`
          }}
        </template>
      </Column>
      <Column
        field="stations"
        :header="t('detail.rate.header.stations')"
        header-class="font-bold"
        class="table__chargers"
      >
        <template #body="slotProps">
          {{ slotProps.data['stationCount'] }}
        </template>
      </Column>
      <Column
        field="range"
        :header="t('detail.rate.header.range')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            type="button"
            rounded
            outlined
            :style-header="`badgeStatus badgeStatus__${getStatusEnabledHours(slotProps.data['schedule'])}`"
            :style-content="`status status__${getStatusEnabledHours(slotProps.data['schedule'])}`"
            :content="`${evaluateRangeHours(slotProps.data['schedule'])}`"
          />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              class="button button-normal mr-2"
              v-tooltip.top="t('detail.rate.actions.update')"
              rounded
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="20" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              class="button button-remove"
              v-tooltip.top="t('detail.rate.actions.delete')"
              rounded
              @click="handleRemove($event, slotProps.data.id)"
            >
              <template #icon>
                <svg-icon name="trash" size="18" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          :pt="{
            pageButton: ({ context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
    </DataTable>
  </div>
  <BaseDialog
    v-model:visible="activeUpdateRateDialog"
    :closable="false"
    :style="{ width: '26vw', minWidth: '26rem', maxWidth: '36rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">{{ t('detail.rate.dialog.edit') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="rate" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column justify-content-between">
        <div class="field mt-2 mb-6">
          <label class="font-family-light" for="name">{{ t('detail.rate.dialog.name') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="selectedRate['alias']"
              id="alias"
              required="true"
              autofocus
              :placeholder="t('detail.rate.dialog.placeholder.name')"
              :value="capitalizeString(selectedRate['alias']) || ''"
            />
          </IconField>
        </div>
        <div class="field">
          <label class="font-family-light" for="cost">{{ t('detail.rate.dialog.cost') }}</label>
          <BaseInputNumber
            v-model="selectedRate['flatRate']"
            :placeholder="t('detail.rate.dialog.placeholder.cost')"
            :suffix="`${selectedRate['unit']}/${selectedRate['currency']}`"
            :step="0.01"
            :min="0"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="confirmUpdateRate" :remove="hidden" />
      </div>
    </template>
  </BaseDialog>
  <BaseDialog
    v-model:visible="activeCreationRateDialog"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">{{ t('detail.header.addRate') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="rate" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column justify-content-between">
        <div class="flex flex-row justify-content-between">
          <div class="field col-6">
            <label class="font-family-light" for="name">{{
              t('detail.station.dialog.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="alias"
                id="name"
                required="true"
                autofocus
                :placeholder="t('detail.rate.dialog.placeholder.name')"
              />
            </IconField>
          </div>
          <div class="field col-6">
            <label class="font-family-light" for="cost">{{
              t('detail.rate.dialog.costBase')
            }}</label>
            <BaseInputNumber
              v-model="costKwh"
              :min="0"
              :placeholder="t('detail.rate.dialog.cost')"
              :step="0.01"
              suffix=" €"
            />
            <small class="text-gray-500">{{ t('detail.rate.dialog.costBase') }}</small>
          </div>
        </div>
        <div class="field col-12 p-0">
          <DataTable :value="hoursRanges" scrollable>
            <template #header>
              <div class="flex align-items-center">
                <span class="font-bold text-2xl">{{ t('detail.rate.header.timetable') }}</span>
                <Button
                  v-tooltip.top="t('detail.rate.dialog.createRangeHour')"
                  rounded
                  disabled
                  class="button button-normal ml-3 w-2rem h-2rem"
                  @click="addNewRangeHour"
                >
                  <template #icon>
                    <i class="font-bold pi pi-plus text-xs" />
                  </template>
                </Button>
              </div>
            </template>
            <Column
              field="code"
              :header="t('detail.rate.header.timetable')"
              header-class="font-bold"
              class="timetable__time"
            ></Column>
            <Column
              field="name"
              :header="t('detail.rate.header.cost')"
              header-class="font-bold"
              class="timetable__cost"
            ></Column>
            <Column
              field="category"
              :header="t('detail.rate.header.start')"
              header-class="font-bold"
              class="timetable__start"
            />
            <Column
              field="quantity"
              :header="t('detail.rate.header.end')"
              header-class="timetable__end"
              class=""
            />
            <Column
              field="quantity2"
              :header="t('detail.rate.header.range')"
              header-class="font-bold"
              class="timetable__range"
            />
            <Column
              :header="t('detail.header.actions')"
              header-class="table__header font-bold"
              class="timetable__actions"
            >
              <template #body="slotProps">
                <div class="flex flex-row justify-content-center">
                  <Button
                    class="button button-normal mr-2"
                    v-tooltip.top="t('detail.rate.dialog.editRangeHour')"
                    disabled
                    rounded
                    @click="handleEditHoursRange(slotProps.data)"
                  >
                    <template #icon>
                      <svg-icon name="edit" size="20" color="#626868" />
                    </template>
                  </Button>
                  <BaseConfirmDeletePopup ref="popup" />
                  <Button
                    class="button button-remove"
                    v-tooltip.top="t('detail.rate.dialog.removeRangeHour')"
                    rounded
                    @click="handleRemove($event, slotProps.data)"
                  >
                    <template #icon>
                      <svg-icon name="trash" size="18" />
                    </template>
                  </Button>
                </div>
              </template>
            </Column>
            <BaseDialog
              v-model:visible="activeCreateRangeHourDialog"
              :closable="false"
              :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
            >
              <template #title>
                <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
                  <p class="p-dialog-title">{{ t('detail.rate.dialog.addRangeHours') }}</p>
                </div>
              </template>
              <template #header>
                <div class="absolute top-0 right-0 mt-4 mr-3">
                  <svg-icon name="clock" size="24" color="white" />
                </div>
              </template>
              <template #body>
                <div class="flex flex-column justify-content-between">
                  <div class="flex flex-colum justify-content-between">
                    <div class="field col-6">
                      <label class="font-family-light" for="name">{{
                        t('detail.rate.dialog.name')
                      }}</label>
                      <IconField icon-position="left">
                        <InputIcon>
                          <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
                        </InputIcon>
                        <InputText
                          v-model="selectedRate['alias']"
                          id="alias"
                          required="true"
                          autofocus
                          :placeholder="t('detail.rate.dialog.placeholder.name')"
                        />
                      </IconField>
                    </div>
                    <div class="field col-6">
                      <label class="font-family-light" for="cost">{{
                        t('detail.rate.dialog.cost')
                      }}</label>
                      <BaseInputNumber
                        v-model="selectedRate['flatRate']"
                        :min="0"
                        suffix=" €"
                        :step="0.01"
                        :placeholder="t('detail.rate.dialog.placeholder.cost')"
                      />
                    </div>
                  </div>
                  <BaseSchedule :header="t('detail.rate.dialog.selectHeader')" />
                </div>
              </template>
              <template #footer>
                <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
                  <FooterDialog
                    @cancel="handleCancelAddRangeHours"
                    @confirm="confirmUpdateRangeHours"
                    :remove="hidden"
                  />
                </div>
              </template>
            </BaseDialog>
            <BaseDialog
              v-model:visible="editRangeHourDialog"
              :closable="false"
              :style="{ width: '26vw', minWidth: '26rem', maxWidth: '36rem' }"
            >
              <template #title>
                <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
                  <p class="p-dialog-title">{{ t('detail.rate.dialog.editRangeHours') }}</p>
                </div>
              </template>
              <template #header>
                <div class="absolute top-0 right-0 mt-4 mr-3">
                  <svg-icon name="clock" size="24" color="white" />
                </div>
              </template>
              <template #body>
                <div class="flex flex-column justify-content-between">
                  <div class="field mt-2 mb-6">
                    <label class="font-family-light" for="name">{{
                      t('detail.rate.dialog.name')
                    }}</label>
                    <IconField icon-position="left">
                      <InputIcon>
                        <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
                      </InputIcon>
                      <InputText
                        v-model="selectedRate['alias']"
                        id="alias"
                        required="true"
                        autofocus
                        :placeholder="t('detail.rate.dialog.placeholder.name')"
                      />
                    </IconField>
                  </div>
                  <div class="field">
                    <label class="font-family-light" for="cost">{{
                      t('detail.rate.dialog.cost')
                    }}</label>
                    <BaseInputNumber
                      v-model="selectedRate['flatRate']"
                      :step="0.1"
                      :placeholder="t('detail.rate.dialog.placeholder.cost')"
                      :min="0"
                      :suffix="`${selectedRate['currency']}/${selectedRate['unit']}`"
                    />
                  </div>
                </div>
              </template>
              <template #footer>
                <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
                  <FooterDialog
                    @cancel="handleCancelEditRangeHours"
                    @confirm="confirmUpdateRangeHours"
                    :remove="hidden"
                  />
                </div>
              </template>
            </BaseDialog>
          </DataTable>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="confirmInsert" :remove="hidden" />
      </div>
    </template>
  </BaseDialog>
  <BasePushNotification group="success" icon-name="restart" color="#00DB7F" />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

/* Rate table */

::v-deep(.table__rate) {
  width: 29%;
}

::v-deep(.table__type) {
  width: 16%;
}

::v-deep(.table__cost) {
  width: 14%;
}

::v-deep(.table__chargers) {
  width: 10%;
}

::v-deep(.table__status) {
  width: 20%;
}

::v-deep(.table__actions) {
  width: 11%;
}

/* Rate creation table */

::v-deep(.timetable__time) {
  width: 14%;
}

::v-deep(.timetable__cost) {
  width: 18%;
}

::v-deep(.timetable__start) {
  width: 12%;
}

::v-deep(.timetable__end) {
  width: 12%;
}

::v-deep(.timetable__range) {
  width: 25%;
}

::v-deep(.timetable__actions) {
  width: 19%;
}
</style>
