import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type { MeterValuesBroadcast } from '@/models/ocpp/Broadcast'
import type { MeterValuesRequest } from '@/models/ocpp/MeterValuesRequest'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'
import { Measurand, UnitOfMeasure } from '@/models/ocpp/enums'

export const useMeterValues = defineStore('meterValues', {
  state: () => ({
    meterValues: {} as { [key: string]: MeterValuesRequest & { cpId: string } }
  }),
  getters: {
    getMeterValues: (state) => state.meterValues,
    getMeterValuesByKey: (state) => (key: string) => {
      return state.meterValues[key]
    },

    getMeterValuesByCpId: (state) => (cpId: string, numberOfConnectors: number) => {
      return Array.from({ length: numberOfConnectors }, (_, i) => {
        const key = `${cpId}:${i + 1}`
        return state.meterValues[key]
      })
    },

    getSuppliedEnergy: (state) => (cpId: string, connectorId: number) => {
      const meterValues = state.meterValues[`${cpId}:${connectorId}`]
      if (!meterValues) return 0
      const lastMeterValue = meterValues.meterValue[meterValues.meterValue.length - 1]
      const sampledValue = lastMeterValue.sampledValue
      const energy =
        sampledValue.length === 1
          ? sampledValue[0]
          : sampledValue.find((sv) => sv.measurand === Measurand.ENERGY_ACTIVE_IMPORT_REGISTER)
      if (!energy) return null
      const suppliedEnergy = parseInt(energy.value)
      if (!energy.unit || energy.unit === UnitOfMeasure.WH) return suppliedEnergy / 1000
      if (energy.unit !== UnitOfMeasure.KWH) return null
      return suppliedEnergy
    }
  },

  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.meterValues, (data: MeterValuesBroadcast) => {
        const { cpId, payload } = data
        const connectorId = payload.connectorId
        this.setMeterValues(cpId, connectorId, payload)
      })
    },

    setMeterValues(cpId: string, connectorId: number, meterValues: MeterValuesRequest) {
      this.meterValues = {
        ...this.meterValues,
        [`${cpId}:${connectorId}`]: { ...meterValues, cpId }
      }
    }
  }
})
