<script setup lang="ts">
import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { array, boolean, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import { useApi } from '@/stores/api/api'
import { ApiService } from '@/services/ApiService'
import BaseInputSwitch from '@/components/ui/BaseInputSwitch.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { capitalizeString } from '@/utils/capitalize'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { type Location, type User } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'

const { availableLocations, updatedUser, updatedUserId, updating, toasting } = defineProps<{
  availableLocations: Partial<Location>[]
  updatedUser?: Partial<User>
  updatedUserId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshUsers'): void
}>()

const { loading } = storeToRefs(useApi())

// TODO: Error messages i18n
const schema = toTypedSchema(
  object({
    active: boolean().default(false),
    email: string()
      .email(() => t('validation.isEmail'))
      .max(50, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    hasRfid: boolean().default(false),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    phone: string()
      .max(50, ({ max }) => t('validation.max', { max }))
      .default(''),
    surname: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    locations: array().default([])
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [active] = defineField('active')
const [email] = defineField('email')
// TODO: Implement hasRfid
const [hasRfid] = defineField('hasRfid')
const [name] = defineField('name')
const [phone] = defineField('phone')
const [surname] = defineField('surname')
const [locations] = defineField('locations')

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedUserId) {
      await ApiService.updateEntity(RouteNamespace.users, updatedUserId, values)
    } else {
      await ApiService.createEntity(RouteNamespace.users, values)
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.user.notifications.updateSuccess')
        : t('detail.user.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.user.notifications.updateError')
        : t('detail.user.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshUsers')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
watch(visible, () => {
  // TODO: Implement locations
  resetForm({
    values: { ...updatedUser }
  })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedUser" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.user.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ capitalizeString(updatedUser?.name || '') }}
            {{ capitalizeString(updatedUser?.surname || '') }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.user.actions.create') }}</p>
      </div>
      <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
        <svg-icon name="user-face" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label for="name" class="required">{{ t('detail.user.dialog.name') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="user-face" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :invalid="!!errors.name"
              :placeholder="t('detail.user.dialog.placeholder.name')"
              :value="capitalizeString(name ?? '')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-6">
          <label for="surname">{{ t('detail.user.dialog.surname') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="user" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="surname"
              id="surname"
              type="text"
              aria-describedby="surname-help"
              :invalid="!!errors.surname"
              :placeholder="t('detail.user.dialog.placeholder.surname')"
              :value="capitalizeString(surname ?? '')"
            />
          </IconField>
          <small id="surname-help" class="p-error">
            {{ errors.surname }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-6">
          <label for="phone">{{ t('detail.user.dialog.phone') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="phone" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="phone"
              id="phone"
              type="text"
              aria-describedby="phone-help"
              :invalid="!!errors.phone"
              :placeholder="t('detail.user.dialog.placeholder.phone')"
            />
          </IconField>
          <small id="phone-help" class="p-error">
            {{ errors.phone }}
          </small>
        </div>
        <div class="field col-6">
          <label for="email" class="required">{{ t('detail.user.dialog.email') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="mail" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="email"
              id="email"
              type="text"
              aria-describedby="email-help"
              :invalid="!!errors.email"
              :placeholder="t('detail.user.dialog.placeholder.email')"
            />
          </IconField>
          <small id="email-help" class="p-error">
            {{ errors.email }}
          </small>
        </div>
      </div>
      <div class="flex flex-row align-items-center">
        <div class="field col-6">
          <label for="locations">{{ t('detail.card.dialog.stations') }}</label>
          <MultiSelect
            v-model="locations"
            id="locations"
            aria-describedby="locations-help"
            class="h-3rem align-items-center"
            display="chip"
            optionLabel="name"
            optionValue="id"
            :options="availableLocations"
            :invalid="!!errors.locations"
            :placeholder="t('detail.card.dialog.placeholder.locationsToCard')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </MultiSelect>
          <small id="locations-help" class="p-error">
            {{ errors.locations }}
          </small>
        </div>
        <div class="field col-6">
          <div class="h-3rem">
            <label class="font-family-light" for="active">{{
              t('detail.user.dialog.activateUser')
            }}</label>
          </div>
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center mb-1">
              <BaseInputSwitch v-model="active" id="active" aria-describedby="active-help" />
              <div class="ml-3 text-600">
                {{ active ? t('status.active') : t('status.inactive') }}
              </div>
            </div>
          </div>
          <small id="active-help" class="p-error">
            {{ errors.active }}
          </small>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>
