<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { i18n, t } from '@/common/i18n'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import type { UTCTimestamp } from 'lightweight-charts'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'
import type { Connector, ConnectorType, Rate } from '@/models'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseChart from '@/components/ui/BaseChart.vue'
import { capitalizeString } from '@/utils/capitalize'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import {
  Action,
  AvailabilityStatus,
  AvailabilityType,
  ChargePointStatus,
  Measurand,
  RemoteStartStopStatus,
  UnitOfMeasure,
  UnlockStatus,
  WebSocketStatus as ChargerWsStatus
} from '@/models/ocpp/enums'
import type { MeterValue, MeterValuesWithCpId } from '@/models/ocpp/MeterValuesRequest'
import { getClassForStatusStore, getStatusTranslationStore } from '@/utils/status'
import BaseInputSwitchRows from '@/components/ui/BaseInputSwitchRows.vue'
import { StationStatusMap } from '@/models/domain/location/enums'
import { getMostRecentMeterValue } from '@/utils/getMostRecentMeterValue'
import type { IdTagsDropdown, TypeGraphData } from '@/models/ui/Connector'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import AppTopbar from '@/layout/AppTopbar.vue'
import type { Rfid } from '@/models/domain/station/api/Station'
import {
  useChargerStatuses,
  useMeterValues,
  useResponsesOcpp,
  useStatusNotification
} from '@/stores/ocpp'
import BaseBadge from '@/components/ui/BaseBadge.vue'

//TODO reset chart data when the transaction is changed
//TODO tendría que tener acceso a la transactionId actual, si ya esta acabada aunque este dentro del rango de tiempo, no dibujar???
//TODO HANDLE INDIVIDUAL CONNECTOR AVAILABILITY
const { loading } = storeToRefs(useApi())
const DATA_LIMIT = 15
const CHADEMO = 'CHAdeMO'
const KWH = 'kWh'
const initialData = { time: (Date.now() / 1000) as UTCTimestamp, value: 0 }
const toast = useToast()
const route = useRoute()
const connectors = ref<Connector[]>([])
const connectorTypes = ref<ConnectorType[]>([])
const rates = ref<Rate[]>([])
const expandedRows = ref([])
const totalRecords = ref<number>(1)
const connectorSelected = ref()
const enabled = ref<boolean>(true)
const visible = ref<boolean>(false)
const alias = ref<string>('')
const popup = ref()
const chartType = ref('baseline')
const dataCurrent = ref<Array<Array<TypeGraphData>>>([])
const dataPower = ref<Array<Array<TypeGraphData>>>([])
const cpId = ref<string>('')
const toastSummary = ref('')
const selectIdTagVisible = ref(false)
const selectedIdTag = ref<string>()
const idTags = ref<IdTagsDropdown[]>([])
const selectedNumberConnector = ref(0)
//ocpp switches
const changeAvailabilitySwitch = ref<Array<number>>([])
const changeAvailabilityDisabled = ref<Array<boolean>>([])

//ocpp disabled buttons
const disableAll = ref(false)
const startChargerDisabled = ref<Array<boolean>>([])
const stopChargerDisabled = ref<Array<boolean>>([])
const unlockConnectorDisabled = ref<Array<boolean>>([])

//Charts
const timeScaleOptions = {
  timeVisible: true
}
const chartOptions = ref({
  height: 220,
  layout: {
    textColor: 'darkgray',
    fontFamily: 'Lato'
  },
  grid: {
    vertLines: {
      color: 'rgba(197, 203, 206, 0.5)'
    },
    horzLines: {
      color: 'rgba(197, 203, 206, 0.5)'
    }
  },
  rightPriceScale: {
    borderColor: 'rgba(197, 203, 206, 0.8)'
  },
  timeScale: {
    borderColor: 'rgba(197, 203, 206, 0.8)'
  }
})

//use ocpp stores
const meterValuesStore = useMeterValues()
const chargerStatusesStore = useChargerStatuses()
const statusNotificationStore = useStatusNotification()
const responsesOcppStore = useResponsesOcpp()

const priceScaleOptionPower = {
  localization: {
    priceFormatter: (p: number) => {
      const formattedP = p.toFixed(0)
      return formattedP + ' kW'
    }
  }
}
const priceScaleOptionCurrent = {
  localization: {
    priceFormatter: (p: number) => {
      const formattedP = p.toFixed(0)
      return formattedP + ' A'
    }
  }
}
const seriesOptions = ref({
  topLineColor: '#27A69A',
  topFillColor1: '#A4DAD4',
  topFillColor2: '#ECF7F6'
})

const checkValidTimestamp = (newTimestamp: number, indexConnector: number) => {
  if (!dataCurrent.value[indexConnector]) dataCurrent.value[indexConnector] = []
  const dataArray = dataCurrent.value[indexConnector]
  //UTC+2 return negative value in minutes, uses browser local time as reference
  const offset = new Date(newTimestamp).getTimezoneOffset() * 60 * 1000
  const newTimestampInSeconds = (newTimestamp - offset) / 1000
  if (dataArray.length > 0) {
    const lastIndex = dataArray.length - 1
    const lastTimestamp = dataArray[lastIndex].time

    if (lastTimestamp >= newTimestampInSeconds) {
      console.error('Discarding historical meterValue data')
      return false
    }
  }
  return true
}

const buildObjectPointToDraw = (meterValue: MeterValue, measurand: Measurand) => {
  const timestamp = Date.parse(meterValue.timestamp)
  const filterSampleByCurrent = meterValue.sampledValue.filter(
    (sample) => sample.measurand !== undefined && sample.measurand === measurand
  )
  if (filterSampleByCurrent.length > 0) {
    const value = parseInt(filterSampleByCurrent[0].value)
    //UTC+2 return negative value in minutes, uses browser local time as reference
    const offset = new Date(timestamp).getTimezoneOffset() * 60 * 1000
    const time = (timestamp - offset) / 1000
    return { time, value }
  }
  return
}

const saveNewPoint = (point: TypeGraphData, connectorIndex: number, measurand: Measurand) => {
  if (!dataCurrent.value) dataCurrent.value = []
  if (!dataPower.value) dataPower.value = []
  if (measurand === Measurand.CURRENT_IMPORT) {
    if (!dataCurrent.value[connectorIndex]) dataCurrent.value[connectorIndex] = []
    if (dataCurrent.value[connectorIndex].length >= DATA_LIMIT)
      dataCurrent.value[connectorIndex].shift()
    dataCurrent.value[connectorIndex].push(point)
  } else if (measurand === Measurand.POWER_ACTIVE_IMPORT) {
    if (!dataPower.value[connectorIndex]) dataPower.value[connectorIndex] = []
    if (dataPower.value[connectorIndex].length >= DATA_LIMIT)
      dataPower.value[connectorIndex].shift()
    dataPower.value[connectorIndex].push(point)
  }
}
const getPointToDraw = (newValue: MeterValuesWithCpId) => {
  if (!newValue) return
  if ('connectorId' in newValue && 'transactionId' in newValue && 'meterValue' in newValue) {
    if (newValue.connectorId <= 0) {
      console.warn('Invalid connectorId:', newValue.connectorId)
      return
    }
    const connectorIndex = newValue.connectorId - 1

    if (connectorIndex < 0) {
      console.error('connectorIndex is invalid:', connectorIndex)
      return
    }

    const newMeterValue = getMostRecentMeterValue(newValue.meterValue)
    if (!newMeterValue || !newMeterValue.timestamp) {
      console.error('newMeterValue is invalid or does not have a timestamp')
      return
    }
    const parsedTimestamp = Date.parse(newMeterValue.timestamp)
    if (isNaN(parsedTimestamp) || !checkValidTimestamp(parsedTimestamp, connectorIndex)) return

    const current = buildObjectPointToDraw(newMeterValue, Measurand.CURRENT_IMPORT)
    if (current) saveNewPoint(current, connectorIndex, Measurand.CURRENT_IMPORT)

    const power = buildObjectPointToDraw(newMeterValue, Measurand.POWER_ACTIVE_IMPORT)
    if (power) saveNewPoint(power, connectorIndex, Measurand.POWER_ACTIVE_IMPORT)
  } else {
    console.error('newValue does not have the required properties')
  }
}

const initializeArrayData = (length: number, measurand: Measurand): Array<Array<TypeGraphData>> => {
  return Array.from({ length }, (el, index) => {
    const lastMeterValue = meterValuesStore.getMeterValuesByKey(`${cpId.value}:${index + 1}`)
    if (lastMeterValue) {
      //TODO distinguir si seria de la misma transacción??
      const point = buildObjectPointToDraw(
        getMostRecentMeterValue(lastMeterValue.meterValue),
        measurand
      )
      if (point) return [point]
    }
    return [initialData]
  })
}

const initializeRefVariables = () => {
  //Initialize for each connector
  dataCurrent.value = initializeArrayData(totalRecords.value, Measurand.CURRENT_IMPORT)
  dataPower.value = initializeArrayData(totalRecords.value, Measurand.POWER_ACTIVE_IMPORT)
}

const getIdTags = () => {
  if (connectors.value.length === 0) return
  if (!connectors.value[0].station) return
  if (!('cards' in connectors.value[0].station['customer'])) return
  connectors.value[0]?.station['customer'].cards.forEach((rfid: Rfid) => {
    idTags.value.push({ id: rfid.id, name: rfid.alias })
  })
}
const setChargerUI = () => {
  const chargerWsStatus = chargerStatusesStore.getChargerStatusesByCpId(cpId.value)
  const numberOfConnectors = connectors.value.length
  disableAll.value = chargerWsStatus === ChargerWsStatus.DISCONNECTED

  changeAvailabilitySwitch.value = Array.from({ length: numberOfConnectors + 1 }, (v, i) => {
    const connectorStatus = statusNotificationStore.getStatusNotificationByConnector(cpId.value, i)
    if (!connectorStatus) return 0
    return connectorStatus.status === ChargePointStatus.UNAVAILABLE ? 0 : 1
  })

  changeAvailabilityDisabled.value = Array.from({ length: numberOfConnectors + 1 }, (v, i) => {
    const connectorStatus = statusNotificationStore.getStatusNotificationByConnector(cpId.value, i)
    return !connectorStatus || connectorStatus.status === ChargePointStatus.FAULTED
  })

  startChargerDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = statusNotificationStore.getStatusNotificationByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return ![ChargePointStatus.AVAILABLE, ChargePointStatus.PREPARING].includes(
      connectorStatus.status
    )
  })

  stopChargerDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = statusNotificationStore.getStatusNotificationByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return ![
      ChargePointStatus.CHARGING,
      ChargePointStatus.SUSPENDED_EVSE,
      ChargePointStatus.SUSPENDED_EV
    ].includes(connectorStatus.status)
  })

  unlockConnectorDisabled.value = Array.from({ length: numberOfConnectors }, (v, i) => {
    const connectorStatus = statusNotificationStore.getStatusNotificationByConnector(
      cpId.value,
      i + 1
    )
    if (!connectorStatus) return true
    return [ChargePointStatus.UNAVAILABLE, ChargePointStatus.AVAILABLE].includes(
      connectorStatus.status
    )
  })
}
const showExpandedData = (row: object) => {
  connectorSelected.value = row
}

const handleToast = (group, severity, summary) => {
  toastSummary.value = summary
  toast.add({
    life: 3000,
    group,
    severity
  })
}

const handleOCPPRequest = async (connector: Connector | number, route: string) => {
  try {
    let payload = {}
    let connectorId = 0
    if (typeof connector === 'number') connectorId = connector
    else {
      if ('connection' in connector) {
        connectorId = connector.connection
      }
    }
    //TODO disable all buttons if station is not enabled
    switch (route) {
      case RouteNamespace.remoteStartTransaction: {
        startChargerDisabled.value[connectorId - 1] = true
        connectorId = selectedNumberConnector.value
        selectIdTagVisible.value = false
        const statusStation = statusNotificationStore.getStatusNotificationByConnector(
          cpId.value,
          connectorId
        )
        const chargerWsStatus = chargerStatusesStore.getChargerStatusesByCpId(cpId.value)
        if (chargerWsStatus === ChargerWsStatus.DISCONNECTED || !statusStation) {
          handleToast(
            RouteNamespace.remoteStartTransaction,
            'error',
            'Connector not available to start transaction'
          )
          break
        }
        const status = statusStation.status
        if (![ChargePointStatus.AVAILABLE, ChargePointStatus.PREPARING].includes(status)) {
          handleToast(
            RouteNamespace.remoteStartTransaction,
            'error',
            'Connector not available to start transaction'
          )
          break
        }

        const result = await handleStatusRequest(route, {
          cpId: cpId.value,
          connectorId,
          cardId: selectedIdTag.value
        })
        if (result['statusCode'] !== 200) {
          handleToast(RouteNamespace.remoteStartTransaction, 'error', 'Error starting transaction')
          startChargerDisabled.value[connectorId - 1] = false
          break
        }

        handleToast(
          RouteNamespace.remoteStartTransaction,
          'success',
          t('detail.connector.notification.requestSend')
        )
        break
      }

      case RouteNamespace.remoteStopTransaction: {
        payload = {
          cpId: cpId.value,
          connectorId: connectorId
        }
        await handleStatusRequest(route, payload as never)
        toast.add({
          life: 3000,
          group: RouteNamespace.remoteStopTransaction,
          severity: 'error'
        })
        toastSummary.value = t('detail.connector.notification.requestSend')
        break
      }

      case RouteNamespace.changeAvailability: {
        changeAvailabilityDisabled.value[connectorId] = true
        disableAll.value = true
        payload = {
          cpId: cpId.value,
          connectorId: connectorId,
          type:
            statusNotificationStore.getStatusNotificationByConnector(cpId.value, connectorId)
              ?.status === ChargePointStatus.UNAVAILABLE
              ? AvailabilityType.OPERATIVE
              : AvailabilityType.INOPERATIVE
        }
        await handleStatusRequest(route, payload as never)
        break
      }

      case RouteNamespace.unlockConnector: {
        unlockConnectorDisabled.value[connectorId] = true
        payload = {
          cpId: cpId.value,
          connectorId: connectorId
        }
        await handleStatusRequest(route, payload as never)
        toast.add({
          life: 3000,
          group: RouteNamespace.unlockConnector,
          severity: 'warn',
          detail: i18n.global.t('notifications.unlockConnector')
        })
        break
      }

      default: {
        console.log('Unknown route:', route)
        break
      }
    }
  } catch (e) {
    console.log(e)
  }
}

const handleStatusRequest = async (route: string, payload: unknown) => {
  try {
    loading.value = true
    visible.value = false
    const result = await ApiService.createEntity(`${RouteNamespace.ocpp}/${route}`, payload)
    loading.value = false
    if (!result || !result['statusCode']) {
      return {
        statusCode: 500
      }
    }
    return result
  } catch (e) {
    return {
      statusCode: 500
    }
  }
}

const getConnectors = async () => {
  try {
    const { station, name } = route.params
    alias.value = name.toString()
    connectors.value = await ApiService.readEntity(
      `${RouteNamespace.connectors}/${station.toString()}`
    )
    //TODO at least one connector
    if (connectors.value[0].station?.cpId) {
      cpId.value = connectors.value[0].station?.cpId
    }
    totalRecords.value = connectors.value.length
  } catch (error) {
    console.error('Error retrieving locations:', error)
  } finally {
    loading.value = false
  }
}

const getConnectorsTypes = async () => {
  try {
    connectorTypes.value = await ApiService.readAllEntities(RouteNamespace.connectorsTypes)
  } catch (error) {
    console.error('Error retrieving connector types:', error)
  } finally {
    loading.value = false
  }
}

const getRates = async () => {
  try {
    loading.value = true
    rates.value = await ApiService.readAllEntities(RouteNamespace.rates)
  } catch (error) {
    console.error('Error retrieving rates:', error)
  } finally {
    loading.value = false
  }
}

const handleSelection = (event: Event) => {
  enabled.value = true
  connectorSelected.value = event['data']
}
watch(
  () => responsesOcppStore.changeAvailability,
  (newValues) => {
    Object.values(newValues).forEach((newValue) => {
      if (!newValue) return
      if (Action.CHANGE_AVAILABILITY) {
        if (newValue.payload.status === AvailabilityStatus.REJECTED) {
          changeAvailabilityDisabled.value = changeAvailabilityDisabled.value.fill(false)
          disableAll.value = false
        }
      } else if (Action.REMOTE_START_TRANSACTION) {
        if (RemoteStartStopStatus.REJECTED) {
          startChargerDisabled.value[newValue.request.connectorId] = false
        }
      } else if (Action.REMOTE_STOP_TRANSACTION) {
        if (RemoteStartStopStatus.REJECTED) {
          stopChargerDisabled.value[newValue.request.connectorId] = false
        }
      } else if (Action.UNLOCK_CONNECTOR) {
        if (UnlockStatus.UNLOCK_FAILED) {
          unlockConnectorDisabled.value[newValue.request.connectorId - 1] = false
        }
      }
    })
  },
  { deep: true }
)
watch(
  () => responsesOcppStore.unlockConnector,
  (newValues) => {
    Object.values(newValues).forEach((newValue) => {
      if (!newValue) return
      if (Action.UNLOCK_CONNECTOR) {
        if (newValue.payload.status === UnlockStatus.UNLOCK_FAILED) {
          unlockConnectorDisabled.value[newValue.request.connectorId - 1] = false
        }
      }
    })
  },
  { deep: true }
)
watch(
  () => meterValuesStore.meterValues,
  (newValues) => {
    if (!cpId.value) return
    try {
      const newMeterValue = Object.values(newValues).filter(
        (newValue) => newValue.cpId === cpId.value
      )
      if (newMeterValue.length !== 1) return
      getPointToDraw(newMeterValue[0])
    } catch (err) {
      console.error('Failed to register the new meterValue', err)
    }
  },
  { deep: true }
)

watch(
  () => chargerStatusesStore.chargerStatuses,
  (newValues) => {
    try {
      for (const key in newValues) {
        const value = newValues[key]
        if (value === ChargerWsStatus.DISCONNECTED) {
          disableAll.value = true
          return
        }
      }
      disableAll.value = false
    } catch (err) {
      console.error('Failed to register the new meterValue', err)
    }
  },
  { deep: true }
)
watch(
  () => statusNotificationStore.statusNotification,
  (newStatuses) => {
    Object.values(newStatuses).forEach((newValue) => {
      if (!newValue) return

      const connectorId = newValue.connectorId
      changeAvailabilitySwitch.value[connectorId] =
        newValue.status === ChargePointStatus.UNAVAILABLE ? 0 : 1

      if (changeAvailabilityDisabled.value[connectorId]) {
        changeAvailabilityDisabled.value[connectorId] = false
      }

      if (connectorId > 0) {
        startChargerDisabled.value[connectorId - 1] = ![
          ChargePointStatus.AVAILABLE,
          ChargePointStatus.PREPARING
        ].includes(newValue.status)

        stopChargerDisabled.value[connectorId - 1] = ![
          ChargePointStatus.CHARGING,
          ChargePointStatus.SUSPENDED_EVSE,
          ChargePointStatus.SUSPENDED_EV
        ].includes(newValue.status)

        unlockConnectorDisabled.value[connectorId - 1] = [
          ChargePointStatus.UNAVAILABLE,
          ChargePointStatus.AVAILABLE
        ].includes(newValue.status)
      }
      disableAll.value = false
    })
  },
  { deep: true }
)
onMounted(async () => {
  try {
    await Promise.all([getConnectors(), getConnectorsTypes(), getRates()])
    getIdTags()
    initializeRefVariables()
    setChargerUI()
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl mb-3">
        <div>
          <Button
            class="text-500 hover:bg-teal-50"
            icon="pi pi-arrow-left"
            :label="`${t('dashboard.locations')} / ${route.params.name}`"
            text
            as="router-link"
            :to="`/${RouteNamespace.locations}/${route.params.id}`"
          />
        </div>
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex align-items-center">
            <span class="font-bold mr-2">{{ t('dashboard.chargePoint') }}</span>
            <span class="font-family-light font-italic alias">{{ capitalizeString(alias) }}</span>
          </div>
          <div class="flex flex-row align-items-center">
            <BaseInputSwitchRows
              :operative="changeAvailabilitySwitch.some((v) => v === 1) ? 1 : 0"
              :disabled="disableAll || changeAvailabilityDisabled.some((v) => v === true)"
              @click="handleOCPPRequest(0, RouteNamespace.changeAvailability)"
            />
            <div class="ml-3 text-600 text-lg">
              {{
                changeAvailabilitySwitch.some((v) => v === 1)
                  ? t('detail.location.status.active')
                  : t('detail.location.status.inactive')
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:expanded-rows="expandedRows"
      :value="connectors"
      :rowHover="true"
      @rowSelect="handleSelection"
      @rowExpand="showExpandedData"
      sortField="connection"
      :sortOrder="1"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.connector.notFound')" />
      </template>
      <template #header>
        <div class="flex justify-content-between h-3rem">
          <div class="flex align-items-center justify-content-between">
            <svg-icon name="connector" size="24" color="#9E9E9E" />
            <span class="font-family-light ml-2 text-2xl">{{
              t('detail.connector.header.connectors')
            }}</span>
          </div>
        </div>
      </template>
      <Column expander class="table__expander" />
      <Column
        field="connection"
        :header="t('detail.connector.header.connector')"
        header-class="font-bold"
        class="table__connection"
      >
        <template #body="slotProps">
          <span>{{ slotProps['data']['connection'] }}</span>
        </template>
      </Column>
      <Column
        field="connectors.connectorType"
        :header="t('detail.connector.header.type')"
        header-class="font-bold"
        class="table__type"
      >
        <template #body="slotProps">
          <div class="flex align-items-center">
            <svg-icon
              :name="`${slotProps.data['connectorType']['connector']}`"
              size="24"
              color="#9E9E9E"
            />
            <span class="ml-2">{{
              slotProps.data['connectorType']['connector'] !== CHADEMO
                ? slotProps.data['connectorType']['connector'].toUpperCase()
                : slotProps.data['connectorType']['connector']
            }}</span>
          </div>
        </template>
      </Column>
      <Column
        field="connectorType.power"
        :header="t('detail.connector.header.powerNominal')"
        header-class="font-bold"
        class="table__power"
      >
        <template #body="slotProps">
          <span>{{ slotProps['data']['maxPower'] }} {{ KWH }}</span>
        </template>
      </Column>
      <Column
        field="connectorType.energy"
        :header="t('detail.connector.header.energySupplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          <span>{{
            `${meterValuesStore.getSuppliedEnergy(slotProps.data.station.cpId, slotProps.data.connection)} ${UnitOfMeasure.KWH} `
          }}</span>
        </template>
      </Column>
      <Column
        field="status"
        :header="t('detail.connector.header.status')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            rounded
            outlined
            :style-header="`badgeStatus badgeStatus__${getClassForStatusStore(
              chargerStatusesStore.getChargerStatusesByCpId(slotProps.data.station.cpId) ===
                ChargerWsStatus.CONNECTED
                ? (statusNotificationStore.getStatusNotificationByConnector(
                    slotProps.data.station.cpId,
                    slotProps.data.connection
                  )?.status ?? StationStatusMap.DISCONNECTED)
                : StationStatusMap.DISCONNECTED
            )}`"
            :style-content="`status status__${getClassForStatusStore(
              chargerStatusesStore.getChargerStatusesByCpId(slotProps.data.station.cpId) ===
                ChargerWsStatus.CONNECTED
                ? (statusNotificationStore.getStatusNotificationByConnector(
                    slotProps.data.station.cpId,
                    slotProps.data.connection
                  )?.status ?? StationStatusMap.DISCONNECTED)
                : StationStatusMap.DISCONNECTED
            )}`"
            :content="
              getStatusTranslationStore(
                chargerStatusesStore.getChargerStatusesByCpId(slotProps.data.station.cpId) ===
                  ChargerWsStatus.CONNECTED
                  ? (statusNotificationStore.getStatusNotificationByConnector(
                      slotProps.data.station.cpId,
                      slotProps.data.connection
                    )?.status ?? StationStatusMap.DISCONNECTED)
                  : StationStatusMap.DISCONNECTED
              )
            "
          />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <BaseDialog v-model:visible="selectIdTagVisible" :style="{ width: '20vw' }">
            <template #title>
              <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
                <div class="flex flex-row">
                  <p class="p-dialog-title mr-1">
                    {{ t('detail.connector.cards.dialog.placeholder') }}
                  </p>
                  <p class="p-dialog-title font-family-light font-italic">
                    {{ `${t('detail.connector.header.connector')} ${selectedNumberConnector}` }}
                  </p>
                </div>
              </div>
            </template>
            <template #header>
              <div class="absolute top-0 right-0 mt-4 mr-3">
                <svg-icon name="rfid-card" size="24" color="white" />
              </div>
            </template>
            <template #body>
              <div class="flex flex-row items-center gap-5 mt-2 mb-4">
                <div class="field col-12">
                  <Select
                    v-model="selectedIdTag"
                    :options="idTags"
                    optionLabel="name"
                    optionValue="id"
                    :placeholder="t('detail.connector.cards.dialog.placeholder')"
                    :emptyMessage="t('detail.card.notFound')"
                    checkmark
                    :highlightOnSelect="false"
                    :pt="{
                      item: ({ props, state, context }) => ({
                        class: context.selected
                          ? 'bg-gray-300'
                          : context.focused
                            ? 'bg-gray-100'
                            : undefined
                      })
                    }"
                  >
                    <template #dropdownicon>
                      <div class="flex flex-column justify-content-center p-0 col-12">
                        <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                      </div>
                    </template>
                  </Select>
                </div>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-content-center gap-2">
                <Button
                  type="button"
                  :label="t('detail.connector.cards.dialog.button.send')"
                  rounded
                  class="button button-save size mr-3"
                  :disabled="!selectedIdTag"
                  @click="
                    handleOCPPRequest(
                      selectedNumberConnector,
                      RouteNamespace.remoteStartTransaction
                    )
                  "
                ></Button>
                <Button
                  type="button"
                  :label="t('actions.cancel')"
                  rounded
                  class="button button-cancel size"
                  @click="
                    () => {
                      selectIdTagVisible = false
                      selectedIdTag = ''
                    }
                  "
                ></Button>
              </div>
            </template>
          </BaseDialog>

          <div class="flex flex-row justify-content-center">
            <Button
              class="button button-start mr-2"
              v-tooltip.top="t('detail.connector.actions.startCharge')"
              rounded
              :disabled="disableAll || startChargerDisabled[slotProps.data.connection - 1]"
              @click="
                () => {
                  selectIdTagVisible = true
                  selectedNumberConnector = slotProps.data.connection
                }
              "
            >
              <template #icon>
                <svg-icon name="charge" size="20" color="white" />
              </template>
            </Button>
            <Button
              class="button button-cancel mr-2"
              v-tooltip.top="t('detail.connector.actions.stopCharge')"
              rounded
              :disabled="disableAll || stopChargerDisabled[slotProps.data.connection - 1]"
              @click="handleOCPPRequest(slotProps.data, RouteNamespace.remoteStopTransaction)"
            >
              <template #icon>
                <svg-icon name="cancel-charge" size="20" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              class="button button-warning"
              v-tooltip.top="t('detail.connector.actions.unblockPoint')"
              icon="pi pi-trash"
              rounded
              :disabled="disableAll || unlockConnectorDisabled[slotProps.data.connection - 1]"
              @click="handleOCPPRequest(slotProps.data, RouteNamespace.unlockConnector)"
            >
              <template #icon>
                <svg-icon name="disconnected" size="20" color="#2C2C2C" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="flex flex-row col-12">
          <div class="col-6 chart-container">
            <BaseChart
              :id="`${slotProps['data']['station'].cpId}:${slotProps['data'].connection}`"
              :key="`${slotProps['data']['station'].cpId}:${slotProps['data'].connection}`"
              :type="chartType"
              :data="dataCurrent[slotProps['data']['connection'] - 1]"
              :autosize="true"
              :chart-options="{ ...chartOptions, ...priceScaleOptionCurrent }"
              :series-options="seriesOptions"
              :time-scale-options="timeScaleOptions"
              ref="lwChartCurrent"
            />
            <span class="font-italic text-500">{{
              t('detail.connector.charts.energySupplied')
            }}</span>
          </div>
          <div class="col-6 chart-container">
            <BaseChart
              :type="chartType"
              :data="dataPower[slotProps['data']['connection'] - 1]"
              :autosize="true"
              :chart-options="{ ...chartOptions, ...priceScaleOptionPower }"
              :series-options="seriesOptions"
              :time-scale-options="timeScaleOptions"
              ref="lwChartPower"
            />
            <span class="font-italic text-500">{{
              t('detail.connector.charts.counterMeasure')
            }}</span>
          </div>
        </div>
        <div class="flex flex-row p-4 align-items-center">
          <div class="flex flex-row align-items-center w-4">
            <BaseInputSwitchRows
              :operative="changeAvailabilitySwitch[slotProps.data.connection]"
              :disabled="disableAll || changeAvailabilityDisabled.some((v) => v === true)"
              @click="
                handleOCPPRequest(slotProps.data.connection, RouteNamespace.changeAvailability)
              "
            />
            <span class="font-bold text-500 ml-3">
              {{
                changeAvailabilitySwitch[slotProps.data.connection] === 1
                  ? t('detail.location.status.active')
                  : t('detail.location.status.inactive')
              }}</span
            >
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <BasePushNotification
    :title="t('notifications.rechargeStarted')"
    icon-name="connected"
    color="#00DB7F"
    :group="RouteNamespace.remoteStartTransaction"
    severity="success"
    :summary="toastSummary"
  />
  <BasePushNotification
    :title="t('notifications.rechargeStopped')"
    icon-name="disconnected"
    color="#EA2839"
    :group="RouteNamespace.remoteStopTransaction"
    severity="error"
    :summary="t('notifications.rechargeStopped')"
  />
  <BasePushNotification
    :title="t('notifications.unlockConnector')"
    icon-name="disconnected"
    color="#FFC700"
    :group="RouteNamespace.unlockConnector"
    severity="warn"
    :summary="t('notifications.unlockConnector')"
  />
  <Toast group="connectors" />
</template>
<style scoped lang="scss">
::v-deep(.p-inputswitch ::before) {
  background-color: var(--white) !important;
}

::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__expander) {
  width: 4%;
}

::v-deep(.table__connection) {
  width: 11%;
}

::v-deep(.table__type) {
  width: 15%;
}

::v-deep(.table__power) {
  width: 19%;
}

::v-deep(.table__energy) {
  width: 21%;
}

::v-deep(.table__status) {
  width: 13%;
}

::v-deep(.table__actions) {
  width: 11%;
}
</style>
