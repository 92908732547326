import { defineStore } from 'pinia'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
//import { ActionNameSpace } from '@/models/common/ActionNameSpace'

export const useApi = defineStore('api', {
  state: () => ({
    totalPayments: 0,
    totalUsers: 0,
    totalCards: 0,
    loading: false,
    chargers: [],
    connectorTypes: [],
    payments: [],
    roles: []
  }),
  getters: {
    getChargers: (state) => state.chargers,
    getConnectorTypes: (state) => state.connectorTypes,
    getPayments: (state) => state.payments,
    getTotalPayments: (state) => state.totalPayments,
    getTotalUsers: (state) => state.totalUsers,
    getTotalCards: (state) => state.totalCards,
    getRoles: (state) => state.roles,
    getLoading: (state) => state.loading
  },
  actions: {
    async fetchChargers() {
      this.chargers = await ApiService.readAllEntities(RouteNamespace.chargers)
      return this.chargers
    },
    async fetchPayments() {
      this.payments = await ApiService.readAllEntities(RouteNamespace.payments)
      return this.payments
    },
    async fetchRoles() {
      this.roles = await ApiService.readAllEntities(RouteNamespace.roles)
      return this.roles
    },
    async fetchConnectorTypes() {
      this.connectorTypes = await ApiService.readAllEntities(RouteNamespace.connectorsTypes)
      return this.connectorTypes
    }
    /*    async fetchTotalPayments(customerId: string) {
      const sum = await ApiService.totalActions(
        `${RouteNamespace.payments}/${customerId}/${ActionNameSpace.sum}`
      )
      return (this.totalPayments = sum['data'])
    },
    async fetchTotalUsers(customerId: string) {
      const sum = await ApiService.totalActions(
        `${RouteNamespace.users}/${customerId}/${ActionNameSpace.sum}`
      )
      return (this.totalUsers = sum['data'])
    },
    async fetchTotalCards(customerId: string) {
      const sum = await ApiService.totalActions(
        `${RouteNamespace.cards}/${customerId}/${ActionNameSpace.sum}`
      )
      return (this.totalCards = sum['data'])
    }*/
  }
})
