<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { t } from '@/common/i18n'
import { useAuthStore } from '@/stores/auth'
import { useToast } from 'primevue/usetoast'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { ApiService } from '@/services/ApiService'
import { RouteNamespace } from '@/models/common/RouteNameSpace'

const email = ref<string>('')
const emailForgotPass = ref<string>('')
const password = ref<string>('')
const showDialogForgotPass = ref<boolean>(false)
const { login } = useAuthStore()
const toast = useToast()

onMounted(async () => {
  const authStore = useAuthStore()
  if (authStore.isAuthenticated) {
    toast.add({
      group: 'info',
      severity: 'info',
      detail: t('auth.expiredSession'),
      life: 3000
    })
    await authStore.logout()
    authStore.isAuthenticated = true
  }
  authStore.$reset()
})
const isLogin = async (email: string, password: string) => {
  try {
    await login(email, password)
  } catch (error) {
    if (error && error['response']?.status === 401) {
      resetLoginForm()
      toast.add({
        group: 'error',
        severity: 'error',
        detail: t('auth.invalidCredentials'),
        life: 3000
      })
    }
  }
}
const resetLoginForm = () => {
  email.value = ''
  password.value = ''
}
const forgotPass = () => {
  emailForgotPass.value = ''
  showDialogForgotPass.value = true
}
const sendEmailToResetPassword = async (emailForgotPass: string) => {
  try {
    const payload = {
      email: emailForgotPass
    }
    await ApiService.forgotPass(RouteNamespace.forgotPassword, payload)
  } catch (error) {
    console.error('Error sending email to reset password', error)
  } finally {
    showDialogForgotPass.value = false
  }
}
const isDisabled = computed(() => {
  return password.value.length < 4 || password.value.length > 20
})
</script>
<template>
  <div class="flex flex-column xl:flex-row min-h-screen align-content-center overflow-hidden">
    <div class="flex flex-1 login-container" />
    <div class="flex flex-1">
      <div
        class="flex flex-column justify-content-center align-items-center w-full py-8 px-5 sm:px-8"
      >
        <div class="flex flex-column align-items-center">
          <svg-icon name="chargevite" size="110" color="#EA2839" />
          <span class="text-4xl mb-1">{{ t('auth.welcome') }}</span>
        </div>
        <form class="field" @submit.prevent>
          <div class="flex flex-column col-12">
            <label class="font-family-light text-700 text-2xl line-height-3">{{
              t('auth.email')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="mail" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                id="email"
                autofocus
                v-model="email"
                type="email"
                :placeholder="t('auth.placeholder.email')"
                class="w-full md:w-30rem mt-1"
              />
            </IconField>
          </div>
          <div class="flex flex-column col-12">
            <label class="font-family-light text-700 text-2xl line-height-3">{{
              t('auth.password')
            }}</label>
            <IconField icon-position="left">
              <InputIcon style="z-index: 1">
                <svg-icon name="lock" size="18" color="#9E9E9E" />
              </InputIcon>
              <Password
                id="password"
                v-model="password"
                type="password"
                :placeholder="t('auth.placeholder.password')"
                toggleMask
                :inputStyle="{
                  paddingLeft: 'calc((var(--p-form-field-padding-x) * 2) + var(--p-icon-size))',
                  paddingRight: 'calc((var(--p-form-field-padding-x) * 2) + var(--p-icon-size))'
                }"
                inputClass="w-full md:w-30rem mt-1"
                :feedback="false"
                :input-props="{ autocomplete: 'on' }"
                :pt="{
                  meter: {
                    class: 'bg-gray-500'
                  }
                }"
              />
            </IconField>
          </div>
          <div class="flex flex-column justify-content-around align-items-center mt-4 h-6rem">
            <Button
              :type="'submit'"
              :label="t('auth.login')"
              class="p-3 text-l button button-cancel"
              icon="pi pi-arrow-right"
              rounded
              iconPos="right"
              :disabled="isDisabled"
              @click="isLogin(email, password)"
            />
            <Button
              id="forgotPass"
              class="text-700 underline"
              text
              :label="t('auth.forgotPassword')"
              @click="forgotPass"
            />
          </div>
        </form>
      </div>
    </div>
    <BaseDialog v-model:visible="showDialogForgotPass" :closable="false">
      <template #title>
        <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
          <p class="p-dialog-title">{{ t('detail.auth.resetPassword') }}</p>
        </div>
        <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
          <svg-icon name="lock" size="24" color="white" />
        </div>
      </template>
      <template #body>
        <div class="flex flex-column align-items-center">
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="login" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              id="emailForgotPass"
              autofocus
              v-model="emailForgotPass"
              type="email"
              :placeholder="t('auth.placeholder.email')"
              class="w-full md:w-30rem mt-1"
            />
          </IconField>
        </div>
      </template>
      <template #footer>
        <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
          <Button
            id="sendEmailResetPass"
            type="button"
            :label="t('detail.auth.sendEmail')"
            class="p-3 text-l button button-cancel w-3 mt-2"
            icon="pi pi-arrow-right"
            rounded
            iconPos="right"
            @click="sendEmailToResetPassword(emailForgotPass)"
          />
        </div>
      </template>
    </BaseDialog>
    <BasePushNotification group="info" />
    <BasePushNotification group="error" />
  </div>
</template>

<style scoped lang="scss">
.password-icon {
  top: 1.2rem;
  left: 0.5rem;
}

:deep(.password-field input[type='password']) {
  padding-left: 3rem;
}

.login-container {
  background-image: url('@/assets/background-scaled.png');
  background-size: cover;
}
</style>
