<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { i18n, t } from '@/common/i18n'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import { useToast } from 'primevue/usetoast'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { type Customer } from '@/models'
import { useApi } from '@/stores/api/api'
import { storeToRefs } from 'pinia'
import { ApiService } from '@/services/ApiService'
import { evaluateRole } from '@/utils/status'
import { capitalizeString } from '@/utils/capitalize'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import router from '@/router'
import AppTopbar from '@/layout/AppTopbar.vue'
import CustomerCreationDialog from '@/components/customers/CustomerCreationDialog.vue'
import type { CustomerResponse } from '@/models/domain/customer/api/Customer'

const { loading } = storeToRefs(useApi())
const popup = ref()
const updatedCustomer = ref<Partial<Customer>>()
const updatedCustomerId = ref<string>('')
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const customers = ref<Customer[]>([])
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const toast = useToast()

const getCustomers = async () => {
  try {
    const response = await ApiService.readAllEntities<CustomerResponse>(
      `${RouteNamespace.customers}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    customers.value = response['customers']
    totalRecords.value = response['total']
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}
const handleCreate = () => {
  activeCreationDialog.value = true
}
const handleUpdate = (row) => {
  updatedCustomer.value = {
    address: row.address,
    city: row.city,
    country: row.country,
    description: row.description,
    email: row.email,
    identityNumber: row.identityNumber,
    imageProfile: row.imageProfile,
    name: row.name,
    phone: row.phone,
    postcode: row.postcode,
    province: row.province,
    role: row.role
  }
  updatedCustomerId.value = row.id
  activeUpdateDialog.value = true
}
const handleSendMail = async (customer: string) => {
  try {
    await ApiService.sendPasswordMail(`${RouteNamespace.customers}/${customer['id']}`)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.customer.notifications.sentMail'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred refreshing customer:', error)
  }
}
const actionsRemoveCustomer = async (customer: string) => {
  try {
    await ApiService.deleteEntity(`${RouteNamespace.customers}/${customer['id']}`)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.customer.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    await getCustomers()
  }
}
const handleRemove = async (event: Event, row: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveCustomer(row)
      loading.value = false
    },
    undefined
  )
}
const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getCustomers()
}
onMounted(async () => {
  try {
    loading.value = true
    await getCustomers()
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="customer" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.customers') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.customer.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="customers"
      :globalFilterFields="['name', 'surname', 'city', 'email', 'country', 'role']"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.customer.notFound')" />
      </template>
      <Column field="name" :header="t('detail.customer.header.name')" class="table__name">
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['name']) }}
          {{ capitalizeString(slotProps.data['surname']) }}
        </template>
      </Column>
      <Column
        field="role"
        :header="t('detail.customer.header.role')"
        header-class="font-bold"
        class="table__role"
      >
        <template #body="slotProps">
          {{ evaluateRole(slotProps.data['role']) }}
        </template>
      </Column>
      <Column
        field="billing"
        :header="t('detail.customer.header.billing')"
        header-class="font-bold"
        class="table__billing"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['totalPayments']?.toFixed(2)} €` }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.customer.header.energy_supplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${(slotProps.data['totalEnergy'] / 1000).toFixed(2)} kWh` }}
        </template>
      </Column>
      <Column
        field="users"
        :header="t('detail.customer.header.users')"
        header-class="font-bold"
        class="table__users"
      >
        <template #body="slotProps">
          <Tag :value="slotProps.data['totalUsers']" rounded />
        </template>
      </Column>
      <Column
        field="cards"
        :header="t('detail.customer.header.rfidCards')"
        header-class="font-bold"
        class="table__cards"
      >
        <template #body="slotProps">
          <Tag :value="slotProps.data['totalCards']" rounded />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.customer.actions.update')"
              rounded
              class="button button-normal mr-2"
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="16" color="#626868" />
              </template>
            </Button>
            <Button
              v-tooltip.top="t('detail.customer.actions.activation')"
              rounded
              class="button button-warning mr-2"
              @click="handleSendMail(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="mail" size="16" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-tooltip.top="t('detail.customer.actions.delete')"
              icon="pi pi-trash"
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data)"
            >
              <template #icon>
                <svg-icon name="trash" size="16" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          :pt="{
            pageButton: ({ context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
    </DataTable>
  </div>
  <CustomerCreationDialog
    v-model:visible="activeUpdateDialog"
    updating
    :updated-customer="updatedCustomer"
    :updated-customer-id="updatedCustomerId"
    :toasting="toast"
    @refresh-customers="getCustomers()"
  />
  <CustomerCreationDialog
    v-model:visible="activeCreationDialog"
    :toasting="toast"
    @refresh-customers="getCustomers()"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 16%;
}

::v-deep(.table__role) {
  width: 14%;
}

::v-deep(.table__billing) {
  width: 14%;
}

::v-deep(.table__energy) {
  width: 16%;
}

::v-deep(.table__users) {
  width: 11%;
}

::v-deep(.table__cards) {
  width: 13%;
}

::v-deep(.table__actions) {
  width: 16%;
}
</style>
